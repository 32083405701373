import getConfig from '../config';
import { useQuery } from '@tanstack/react-query';

export function useConfig() {
  const { isPending, data } = useQuery({
    queryKey: ['CLIENT-CONFIG'],
    queryFn: async () => {
      const response = await getConfig();
      return response;
    },
    staleTime: Infinity,
  });

  return { isPending, config: data };
}
