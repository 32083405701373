import { useState } from 'react';
import { EVENT_NAMES, sendEvent } from '../../utils/analytics';
import useOnMountEffect from '../../utils/hooks/useOnMountEffect';
import SimpleModal from './modals/SimpleModal';
import { useConfig } from '../../utils/hooks/useConfig';

const UnsupportedBrowserModal = () => {
  const [showModal, setShowModal] = useState(false);
  const { config } = useConfig();

  useOnMountEffect(() => {
    if (config?.SUPPORTED_BROWSERS) {
      const { flags, source } = config?.SUPPORTED_BROWSERS ?? {};
      const supportedBrowsers = new RegExp(source, flags);
      if (!supportedBrowsers.test(navigator.userAgent)) {
        sendEvent({
          name: EVENT_NAMES.unsupportedBrowser,
          properties: {
            userAgent: navigator.userAgent,
          },
        });
      }
    }
    // only show modal on browser we explicitly don't support
    if (config?.UNSUPPORTED_BROWSERS) {
      const { flags, source } = config?.UNSUPPORTED_BROWSERS ?? {};
      const unsupportedBrowsers = new RegExp(source, flags);
      if (unsupportedBrowsers.test(navigator.userAgent)) {
        setShowModal(true);
      }
    }
  });

  if (!showModal) {
    return null;
  }
  return (
    <SimpleModal
      open
      header={'Upgrade browser for best experience'}
      body={'You can continue anyway, but we can’t promise that everything will work as intended.'}
      onClose={() => setShowModal(false)}
    />
  );
};

export default UnsupportedBrowserModal;
