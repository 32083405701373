// eslint-disable-next-line no-restricted-imports
import * as Sentry from '@sentry/nextjs'; // only use as type so it's not included in the bundle
import { getOptions } from './sentryOptions';
import getConfig from '../../../config';

type CaptureExceptionParams = Parameters<typeof Sentry.captureException>;
type CaptureMessageParams = Parameters<typeof Sentry.captureMessage>;
type AddBreadcrumbParams = Parameters<typeof Sentry.addBreadcrumb>;

type State = {
  Sentry?: typeof Sentry;
  queue: (
    | { name: 'captureException'; params: CaptureExceptionParams }
    | { name: 'captureMessage'; params: CaptureMessageParams }
    | { name: 'addBreadcrumb'; params: AddBreadcrumbParams }
  )[];
};

const state: State = {
  queue: [],
};

const ENABLED_TRACING_PATHS = ['/', /\/impacter-tools*/];

export const onLoaded = async () => {
  const Sentry = await import('@sentry/nextjs');
  state.Sentry = Sentry;

  const config = await getConfig();

  Sentry.init(
    getOptions({
      dsn: config.SENTRY_DSN,
      environment: config.ENV,
      release: process.env.SENTRY_RELEASE,
      maxBreadcrumbs: 50,
      attachStacktrace: true,
      tracesSampler: ({ location }) => {
        const pathname = location?.pathname;
        if (!pathname) {
          return 0;
        }

        const enableTracing = ENABLED_TRACING_PATHS.some((path) =>
          path instanceof RegExp ? path.test(pathname) : pathname === path,
        );

        if (enableTracing) {
          return 1;
        }
        return 0;
      },
      beforeSend: (event) => {
        if (event.request?.url && /\/impacter-tools*/.test(event.request.url) && event.tags) {
          event.tags.product = 'impacter-tools';
        }

        return event;
      },
    }),
  );

  Sentry.getCurrentScope().setTag('ssr', 'false');

  state.queue.forEach((item) => {
    switch (item.name) {
      case 'addBreadcrumb':
        addBreadcrumb(...item.params);
        return;
      case 'captureException':
        captureException(...item.params);
        return;
      case 'captureMessage':
        captureMessage(...item.params);
        return;
    }
  });
};

export const captureException = async (...params: CaptureExceptionParams) => {
  const { Sentry } = state;
  if (Sentry) {
    return Sentry.withScope((scope) => {
      const [err] = params;

      if (err.message) {
        // De-duplication currently doesn't work correctly for SSR / browser errors
        // so we force deduplication by error message if it is present
        scope.setFingerprint([err.message]);
      }

      if (err.statusCode) {
        scope.setExtra('statusCode', err.statusCode);
        if (err.statusCode == 404) {
          scope.setLevel('warning');
        }
      }

      return Sentry.captureException(...params);
    });
  }
  state.queue.push({ name: 'captureException', params });
};
export const captureMessage = (...params: CaptureMessageParams) => {
  const { Sentry } = state;
  if (Sentry) {
    return Sentry.captureMessage(...params);
  }
  state.queue.push({ name: 'captureMessage', params });
};
export const addBreadcrumb = (...params: AddBreadcrumbParams) => {
  const { Sentry } = state;
  if (Sentry) {
    return Sentry.addBreadcrumb(...params);
  }
  state.queue.push({ name: 'addBreadcrumb', params });
};

export const lastEventId = () => {
  const { Sentry } = state;
  if (Sentry) {
    return Sentry.lastEventId();
  }
};

export const setUser = (user: Sentry.User | null) => {
  const { Sentry } = state;
  if (Sentry) {
    return Sentry.setUser(user);
  }
};
