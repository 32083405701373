import getConfig from './config';
import { ApiError } from './error';
import { getHost } from './helpers/host';
import { getToken, removeToken } from './token';

export const handleErrorCategory = async (errorType: string, response: Response) => {
  let message = '';
  let json: Record<string, unknown> = {};
  try {
    message = await response.text();
    json = JSON.parse(message);
  } catch {
    message = message || response.statusText;
  }

  throw new ApiError(
    errorType,
    response.status,
    json.errorId as string,
    json.errorCode as string,
    (json.error || json.errorMessage) as string,
    message,
  );
};

export const handleMiscErrors = async (response: Response) => {
  if (response.status >= 400 && response.status < 500) {
    await handleErrorCategory('Client error', response);
  } else if (response.status >= 500 && response.status < 600) {
    await handleErrorCategory('Server error', response);
  }
};

export type QueryParams =
  | string
  | number
  | {
      [x: string]: string | string[] | number | number[] | boolean | undefined | null;
    };

type requestOptions = {
  query?: QueryParams;
  method?: 'GET' | 'POST' | 'PUT' | 'DELETE';
  headers?: {
    [x: string]: string;
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  body?: any;
  fullUrl?: boolean;
};

export const authenticatedFetch = async <T>(
  path: string,
  options: requestOptions = {},
): Promise<T> => {
  const config = await getConfig();
  const url = options.fullUrl ? path : `${config.API_URL}${path}${toQueryString(options.query)}`;

  const response = await fetch(url, {
    method: options.method || 'GET',
    headers: new Headers({
      Authorization: `Bearer ${getToken()}`,
      'x-application-name': 'enterprise',
      ...options.headers,
    }),
    body: options.body,
  });

  if (response.status === 401) {
    removeToken();
    await handleErrorCategory('Unauthorized error', response);
  }

  await handleMiscErrors(response);

  const contentType = response.headers.get('content-type');
  if (contentType?.match('application/json')) {
    const json = await response.json();
    return json as T;
  }

  return response as unknown as T;
};

export async function contentFetch<T>(url: string, options?: requestOptions) {
  return await authenticatedFetch<T>(`content/v2${url}`, options);
}

/**
 * Only supports a specific set of parameters, update in /src/pages/api
 */
export const getFromEnterpriseBackend = async <T>(
  path: string,
  query?: QueryParams,
): Promise<T> => {
  return await get(`${getHost()}/api/${path}`, { query, name: 'Enterprise' });
};

// Their names should be put inline considering the fact that getFromEnterpriseBackend also exist
export const getFromGalaxy = async <T>(
  path: string,
  query?: QueryParams,
  options?: requestOptions,
): Promise<T> => {
  const config = await getConfig();
  return await get<T>(`${config.API_URL}${path}`, { query, name: 'Galaxy' }, options);
};

export const getFromGalaxyCDN = async <T>(
  path: string,
  query?: QueryParams,
  options?: requestOptions,
): Promise<T> => {
  const config = await getConfig();
  return await get<T>(`${config.CDN_API_URL}${path}`, { query, name: 'Galaxy CDN' }, options);
};

export const getFromGalaxyCDNRaw = async (path: string, query?: QueryParams): Promise<Response> => {
  const config = await getConfig();
  const response = await fetch(`${config.CDN_API_URL}${path}${toQueryString(query)}`, {
    headers: { 'x-application-name': 'enterprise' },
  });
  if (!response.ok) await handleErrorCategory('API Galaxy CDN', response);
  return response;
};

export const get = async <T>(
  url: string,
  { query, name }: { query?: QueryParams; name?: string } = {},
  options: requestOptions = {},
): Promise<T> => {
  const response = await fetch(url + toQueryString(query), {
    ...options,
    headers: { 'x-application-name': 'enterprise', ...options.headers },
  });
  if (!response.ok) await handleErrorCategory(name ? `Api ${name}` : 'Api', response);
  return await response.json();
};

export function toQueryString(query?: QueryParams): string {
  if (!query) return '';

  if (typeof query == 'object') {
    // { a: 1, b: [2, 3, undefined], c: undefined } to a=1&b=2&b=3
    query = Object.entries(query)
      .flatMap(([key, values]) =>
        []
          // @ts-expect-error ts-migrate(2769) FIXME: Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
          .concat(values)
          .filter((value) => value !== undefined)
          .map((value) => [encodeURIComponent(key), encodeURIComponent(value)]),
      )
      .map(([key, value]) => `${key}=${value}`)
      .join('&');
  }

  return `?${query}`;
}
