import { getFromEnterpriseBackend } from './api';
import isBrowser from './helpers/isBrowser';
import { getApiUrl, getCdnApiUrl, getHostUrl } from './urls';

type APIClientConfig = {
  ENV: string;
  ASSET_PREFIX: string;
  ALLIANCE_URL: string;
  GALAXY_URL: string;
  CONTENTFUL_SPACE_ID: string;
  CONTENTFUL_ACCESS_TOKEN: string;
  CONTENTFUL_PREVIEW_TOKEN: string;
  CONTENTFUL_ENVIRONMENT: string;
  CONTENTFUL_DELIVERY_HOST: string;
  CONTENTFUL_INSECURE: string;
  FIREBASE_API_KEY: string;
  FIREBASE_AUTH_DOMAIN: string;
  FIREBASE_DATABASE_URL: string;
  FIREBASE_PROJECT_ID: string;
  FIREBASE_STORAGE_BUCKET: string;
  FIREBASE_MESSAGING_SENDER_ID: string;
  FIREBASE_APP_ID: string;
  FIREBASE_MEASUREMENT_ID: string;
  SENTRY_DSN: string;
  AMPLITUDE_KEY: string;
  SUPPORTED_BROWSERS: { flags: string; source: string };
  UNSUPPORTED_BROWSERS: { flags: string; source: string };
  TURNSTILE_SITE_KEY: string;
  CDR_CATEGORY_ID: string;
};

export type ClientConfig = APIClientConfig & {
  CDR_CATEGORY_ID: string;
  HOST_URL: string;
  CDN_API_URL: string;
  API_URL: string;
};

let cachedConfig: APIClientConfig | null = null;

async function getConfig() {
  if (!cachedConfig) {
    cachedConfig = await getFromEnterpriseBackend<APIClientConfig>('config');
  }

  const runtimeConfig = cachedConfig;
  /**
   * Uses relative url for browsers so using 'localhost' is not a must in dev-staging
   */
  const apiBaseUrlGetter = (getApiBaseUrl: (env: string, apiUrl?: string) => string) => {
    if (
      (runtimeConfig.ENV === 'dev-staging' ||
        runtimeConfig.ENV === 'test-staging' ||
        runtimeConfig.ENV === 'dev-production') &&
      isBrowser()
    ) {
      return `/dev-proxy/`;
    }
    return getApiBaseUrl(runtimeConfig.ENV, runtimeConfig.GALAXY_URL);
  };

  const getCDRCategoryId = (env: string) => {
    switch (env) {
      case 'staging':
      case 'dev-staging':
      case 'test-staging':
        return 'cat_clx1uktmz000009miai369y7c';
      case 'production':
      case 'dev-production':
        return 'cat_cm03vvc3o000009nwff4lak9p';
      default:
        return '';
    }
  };

  const config = {
    ...runtimeConfig,
    CDR_CATEGORY_ID: runtimeConfig.CDR_CATEGORY_ID || getCDRCategoryId(runtimeConfig.ENV),
    HOST_URL: getHostUrl(runtimeConfig.ENV),
    CDN_API_URL: apiBaseUrlGetter(getCdnApiUrl),
    API_URL: apiBaseUrlGetter(getApiUrl),
  };

  return config;
}

export default getConfig;
