import { css, Global } from '@emotion/react';
import KHTekaLightSupport from '../../../assets/fonts/KHTeka-Light.woff';
import KHTekaLight from '../../../assets/fonts/KHTeka-Light.woff2';
import KHTekaMediumSupport from '../../../assets/fonts/KHTeka-Medium.woff';
import KHTekaMedium from '../../../assets/fonts/KHTeka-Medium.woff2';
import KHTekaRegularSupport from '../../../assets/fonts/KHTeka-Regular.woff';
import KHTekaRegular from '../../../assets/fonts/KHTeka-Regular.woff2';
import KHTekaRegularItalic from '../../../assets/fonts/KHTeka-RegularItalic.woff2';
import { getAssetUrlWithAssetPrefix } from '../../../utils/assetUrl';

const Fonts = ({ assetPrefix }: { assetPrefix?: string }) => (
  <Global
    styles={css`
      @font-face {
        font-family: 'KH Teka';
        font-weight: 300;
        src: url('${getAssetUrlWithAssetPrefix(KHTekaLight, assetPrefix)}') format('woff2'),
          url('${getAssetUrlWithAssetPrefix(KHTekaLightSupport, assetPrefix)}') format('woff');
      }
      @font-face {
        font-family: 'KH Teka';
        font-weight: 400;
        src: url('${getAssetUrlWithAssetPrefix(KHTekaRegular, assetPrefix)}') format('woff2'),
          url('${getAssetUrlWithAssetPrefix(KHTekaRegularSupport, assetPrefix)}') format('woff');
      }
      @font-face {
        font-family: 'KH Teka';
        font-weight: 400;
        font-style: italic;
        src: url('${getAssetUrlWithAssetPrefix(KHTekaRegularItalic, assetPrefix)}') format('woff2');
      }
      @font-face {
        font-family: 'KH Teka';
        font-weight: 500;
        src: url('${getAssetUrlWithAssetPrefix(KHTekaMedium, assetPrefix)}') format('woff2'),
          url('${getAssetUrlWithAssetPrefix(KHTekaMediumSupport, assetPrefix)}') format('woff');
      }
      * {
        font-family: 'KH Teka', Inter, Arial, sans-serif;
      }
    `}
  />
);

export default Fonts;
